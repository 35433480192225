<template>
  <div class="flex items-center justify-between space-x-4">
    <AppSecondaryButton
      class="w-full p-3"
      :button-text="toTitleCase($t('cancel'))"
      @click="$emit('close')"
    />

    <AppPrimaryButton
      class="w-full p-3"
      :button-text="toTitleCase($t('continue'))"
      @click="onContinue"
    />
  </div>
</template>

<script setup lang="ts">
import { useBookingItineraryStore } from '@/booking-itinerary/store/booking-itinerary.store';
import router, { UNIT_SELECTION_ROUTE } from '@/router';
import { useLocaleString } from '@/string/locale-string.composable';
import AppPrimaryButton from '@/ui/app-primary-button/AppPrimaryButton.vue';
import AppSecondaryButton from '@/ui/app-secondary-button/AppSecondaryButton.vue';

const props = defineProps<{
  unitItineraryItemId: string;
}>();

const { toTitleCase } = useLocaleString();

const bookingItineraryStore = useBookingItineraryStore();

const emit = defineEmits(['close']);

const onContinue = () => {
  bookingItineraryStore.removeUnitItineraryItemById(props.unitItineraryItemId);

  void router.replace({
    name: UNIT_SELECTION_ROUTE,
  });

  emit('close');
};
</script>
